import "./App.css";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import MainGlobalLoader from "./components/portal_components/universal_components/MainGlobalLoader/MainGlobalLoader";

function App(props) {
  const { appLoading } = useContext(AuthContext);

  const { childComp } = props;
  return <div className="App">{childComp}</div>;
}

export default App;
