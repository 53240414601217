import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";
import App from "./App";

// import NotFound from "./pages/NotFound";

import SignInPage from "./pages/Authentication/SignInPage";
import SignUpPage from "./pages/Authentication/SignUpPage";
import ProfilePage from "./pages/Portal/ProfilePage";

import AdminPage from "./pages/Admin/AdminPage";
import DashboardPage from "./pages/Portal/DashboardPage";
import InboxPage from "./pages/Portal/InboxPage";
import ProgramsPage from "./pages/Portal/ProgramsPage";
import ResourcesPage from "./pages/Portal/ResourcesPage";
import SettingsPage from "./pages/Portal/SettingsPage";
import LinkedinAuthPage from "./pages/Authentication/LinkedinAuthPage";
import { AuthContext } from "./context/AuthContext";
import ConnectionsPage from "./pages/Portal/ConnectionsPage";

import CommunityPage from "./pages/Portal/CommunityPage";

import FeedbackPage from "./pages/Portal/FeedbackPage";

function PrivateRoute({ children }) {
  const { currentUser } = useContext(AuthContext);

  // Check for user data in localStorage
  const isUserAuthenticated = localStorage.getItem("authUser") || currentUser;

  return isUserAuthenticated ? children : <Navigate to="/signin" />;
}

function routes() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* ROUTES FOR LANDING PAGE */}
          {/* <Route path="/" element={<App childComp={<AdminPage />} />} /> */}
          <Route
            path="/"
            element={
              <PrivateRoute>
                <App childComp={<CommunityPage />} />
              </PrivateRoute>
            }
          />

          {/* ROUTES FOR SIGNING IN AND SIGNING UP  */}
          <Route path="/signin" element={<App childComp={<SignInPage />} />} />
          <Route path="/signup" element={<App childComp={<SignUpPage />} />} />
          <Route
            path="/linkedinauthorization"
            element={<App childComp={<LinkedinAuthPage />} />}
          />
          <Route
            path="/admin"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/manage-users"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/hubspot-contacts"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/manage-programs"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/manage-resources"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/admin/manage-inquiries"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          />

          <Route
            path="/admin/event-registrations"
            element={
              <PrivateRoute>
                <App childComp={<AdminPage />} />
              </PrivateRoute>
            }
          /> */}

          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <App childComp={<ProfilePage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/:id"
            element={
              <PrivateRoute>
                <App childComp={<ProfilePage />} />
              </PrivateRoute>
            }
          />

          <Route
            path="/community"
            element={
              <PrivateRoute>
                <App childComp={<CommunityPage />} />
              </PrivateRoute>
            }
          />

          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <App childComp={<DashboardPage />} />
              </PrivateRoute>
            }
          />

          <Route
            path="/inbox"
            element={
              <PrivateRoute>
                <App childComp={<InboxPage />} />
              </PrivateRoute>
            }
          />

          <Route
            path="/connections"
            element={
              <PrivateRoute>
                <App childComp={<ConnectionsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/programs"
            element={
              <PrivateRoute>
                <App childComp={<ProgramsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/resources"
            element={
              <PrivateRoute>
                <App childComp={<ResourcesPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <App childComp={<SettingsPage />} />
              </PrivateRoute>
            }
          />
          <Route
            path="/feedback"
            element={<App childComp={<FeedbackPage />} />}
          />
          {/* <Route
            path="/chatbottesting"
            element={<App childComp={<ChatbotTestingPage />} />}
          /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default routes;
