// import "./chat.css";
import { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import React from "react";
import styles from "./profile.module.css";
import { ChatContext } from "../../../context/ChatContext";
import { AuthContext } from "../../../context/AuthContext";
import { FeedContext } from "../../../context/FeedContext";
import ProfilePageContent from "./ProfilePageContent";
import CircularLoader from "../universal_components/CircularLoader/CircularLoader";

export default function ProfileComponent() {
  const { profile, fetchProfileData } = useContext(AuthContext);
  const { fetchPostsByUser } = useContext(FeedContext);
  const [profileData, setProfileData] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setLoadingUser(true);
    const fetchData = async () => {
      try {
        let data;
        if (id) {
          data = await fetchProfileData(id);
          setLoadingUser(false);
        } else {
          data = profile;
          setLoadingUser(false);
        }

        if (data) {
          const posts = await fetchPostsByUser(data.uid);
          data.posts = posts;
          setProfileData(data);
        }
      } catch (error) {
        setLoadingUser(false);
        console.error("Error fetching profile data:", error);
      }
    };

    fetchData();
  }, [id, profile, fetchProfileData, fetchPostsByUser]);

  const isSelfProfile = profileData?.uid === profile?.uid;

  return (
    <div className={styles.main_layout}>
      <div className={styles.cover_image_background}>
        {isSelfProfile && (
          <div className={styles.edit_profile_button}>Edit Account</div>
        )}

        <div className={styles.cover_image_shadow_overlay}></div>
        <img className={styles.cover_image} src="/bg3.jpeg" alt="cover"></img>
      </div>
      <div className={styles.profile_banner}>
        <div className={styles.profile_pic}>
          {loadingUser ? (
            <CircularLoader size={25} />
          ) : (
            <img
              className={styles.profile_pic_image}
              src={
                profileData?.profilePicture
                  ? profileData?.profilePicture
                  : "/DefaultAccountImage.png"
              }
              alt="pic"
            ></img>
          )}
        </div>
        <div className={styles.profile_info_section}>
          <div className={styles.profile_info}>
            <p className={styles.profile_info_title}>
              {profileData?.firstname} {profileData?.lastname}
            </p>
            <p className={styles.profile_info_subtitle}>
              {profileData?.position}
            </p>
          </div>
          <div></div>
        </div>
      </div>
      {/* <div className={styles.profile_page_content}></div> */}
      <ProfilePageContent
        profileData={profileData}
        isSelfProfile={isSelfProfile}
      />
    </div>
  );
}
