import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "../settings.module.css";
import { AuthContext } from "../../../../context/AuthContext";
import CheckboxWithLabel from "../../universal_components/Checkbox/CheckboxWithInfoIcon";

export default function VisibilitySettings(props) {
  const { profile } = props;

  return (
    <div className={styles.section}>
      <p className={styles.section_title}>Visibility Settings</p>
      <div className={styles.profile_section}>
        <div className={styles.profile_picture_section_right}>
          {/* <CheckboxWithLabel
            title="Only Visible to other Care Partners"
            // handler={({ payload }) =>
            //   setValue(name, payload, { shouldValidate: true })
            // }
          />
          <CheckboxWithLabel
            title="Only Visible to other Care Partners"
            // handler={({ payload }) =>
            //   setValue(name, payload, { shouldValidate: true })
            // }
          />

          <CheckboxWithLabel
            title="Only Visible to other Care Partners"
            // handler={({ payload }) =>
            //   setValue(name, payload, { shouldValidate: true })
            // }
          /> */}
          <p className={styles.input_title}>
            ( We can put some options here kind of like linkedin for them to
            manage who can see them, whether its only first or second degree
            connections, or only just others with the same relationship to the
            diagnosed )
          </p>
        </div>
      </div>
    </div>
  );
}
