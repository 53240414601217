import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./creator_search.module.css";
import ConnectionCard from "./ConnectionCard";
import LoadingAnimation from "../UniversalComponents/LoadingAnimation";
import { ChatContext } from "../../../context/ChatContext";

export default function ConnectionLayout(props) {
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const sortByDropdownOptions = ["Date Connected", "Relationship", "Age"];
  const [selectedSortOption, setSelectedSortOption] = useState(
    sortByDropdownOptions[0]
  );

  const { connections, loadingConnections } = useContext(ChatContext);
  const [hoveredSection, setHoveredSection] = useState(null);

  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  const SortDropdown = () => (
    <div className={styles.sortDropdown}>
      {sortByDropdownOptions.map((option) => (
        <div
          key={option}
          className={
            option === selectedSortOption
              ? styles.sortDropdownItemClicked
              : styles.sortDropdownItem
          }
          onClick={() => handleDropdownSelect(option)}
        >
          {option}
        </div>
      ))}
    </div>
  );

  const handleDropdownSelect = (option) => {
    setSelectedSortOption(option);
    setIsDropdownVisible(false);
    // Add sorting logic here if needed
  };

  const handleCreatorCheck = (index, isChecked) => {
    if (isChecked) {
      setSelectedCreators((prev) => [...prev, index]);
    } else {
      setSelectedCreators((prev) => prev.filter((id) => id !== index));
    }
  };

  function handleCheckboxClick(event) {
    event.stopPropagation();
  }

  return (
    <div className={styles.connection_layout}>
      {loadingConnections ? (
        <div className={styles.loading_layout}>
          <LoadingAnimation />
        </div>
      ) : (
        <>
          <div className={styles.creator_list_controls}>
            <div className={styles.creator_list_left_controls}>
              <p className={styles.creator_found_title}>Connections</p>
            </div>
            <div className={styles.creator_list_right_controls}>
              <div className={styles.sort_section} onClick={toggleDropdown}>
                <img
                  className={styles.sort_icon}
                  src="/SortIcon.png"
                  alt="sort"
                ></img>
                <p className={styles.sort_text}>
                  Sort by
                  <span className={styles.chosen_sort_text}>
                    {" "}
                    {selectedSortOption}
                  </span>
                </p>
                <img
                  src="/images/general_icons/DownArrowIcon.png"
                  alt="dropdown"
                  className={`${styles.dropdown_arrow_sort} ${
                    isDropdownVisible ? styles.flipped : ""
                  }`}
                />
              </div>
              {isDropdownVisible && <SortDropdown />}
            </div>
          </div>
          {connections !== [] && (
            <div className={styles.creator_list_fields}>
              <div className={styles.checkbox_field}></div>
              <div className={styles.account_info}>
                <p>Account Info</p>
              </div>
              <div
                className={styles.tile_title_date_section}
                onClick={() => handleDropdownSelect("Date Connected")}
                onMouseEnter={() => setHoveredSection("Date Connected")}
                onMouseLeave={() => setHoveredSection(null)}
              >
                <p
                  className={`${styles.field_titles} ${
                    "Date Connected" === selectedSortOption
                      ? styles.field_titles_selected
                      : ""
                  }`}
                >
                  Date Connected
                </p>
                {selectedSortOption === "Date Connected" && (
                  <img
                    className={styles.arrow_icon}
                    src="./ArrowIconBlue.png"
                    alt="arrow"
                  ></img>
                )}
                {selectedSortOption !== "Date Connected" &&
                  hoveredSection === "Date Connected" && (
                    <img
                      className={styles.arrow_icon_hover}
                      src="./ArrowIconBlack.png"
                      alt="arrow"
                    ></img>
                  )}
              </div>
              <div
                className={styles.tile_title_section}
                onClick={() => handleDropdownSelect("Relationship")}
                onMouseEnter={() => setHoveredSection("Relationship")}
                onMouseLeave={() => setHoveredSection(null)}
              >
                <p
                  className={`${styles.field_titles} ${
                    "Relationship" === selectedSortOption
                      ? styles.field_titles_selected
                      : ""
                  }`}
                >
                  Relationship
                </p>
                {selectedSortOption === "Relationship" && (
                  <img
                    className={styles.arrow_icon}
                    src="./ArrowIconBlue.png"
                    alt="arrow"
                  ></img>
                )}
                {selectedSortOption !== "Relationship" &&
                  hoveredSection === "Relationship" && (
                    <img
                      className={styles.arrow_icon_hover}
                      src="./ArrowIconBlack.png"
                      alt="arrow"
                    ></img>
                  )}
              </div>
              <div
                className={styles.tile_title_section}
                onClick={() => handleDropdownSelect("Age")}
                onMouseEnter={() => setHoveredSection("Age")}
                onMouseLeave={() => setHoveredSection(null)}
              >
                <p
                  className={`${styles.field_titles} ${
                    "Age" === selectedSortOption
                      ? styles.field_titles_selected
                      : ""
                  }`}
                >
                  Age
                </p>
                {selectedSortOption === "Age" && (
                  <img
                    className={styles.arrow_icon}
                    src="./ArrowIconBlue.png"
                    alt="arrow"
                  ></img>
                )}
                {selectedSortOption !== "Age" && hoveredSection === "Age" && (
                  <img
                    className={styles.arrow_icon_hover}
                    src="./ArrowIconBlack.png"
                    alt="arrow"
                  ></img>
                )}
              </div>
              <div className={styles.connect_button_section}></div>
            </div>
          )}
          {/* {connections === [] ?? (
            <div className={styles.loading_layout}>
              <img></img>
        
            </div>
          )} */}

          <div className={styles.creator_list}>
            {connections.map((connection, index) => {
              return (
                <ConnectionCard
                  key={index}
                  connection={connection}
                  isChecked={selectedCreators.includes(index)}
                  onCheck={handleCreatorCheck}
                  index={index}
                  activeTab={props.activeTab}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
