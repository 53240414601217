import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import styles from "../FeedPage/feed.module.css";
import SearchBarHeader from "../universal_components/search_bar_header";
import { FeedContext } from "../../../context/FeedContext";
import axios from "axios";
import { AuthContext } from "../../../context/AuthContext";
import Post from "../FeedPage/feed_post";
import NewPostPrompt from "../FeedPage/feed_create_new_post";
import FavoritedResources from "./FavoritedResources";
import FeedPageSuggested from "../FeedPage/feed_page_suggested";

function ProfilePageContent(props) {
  const { feed } = useContext(FeedContext);
  const profile = props.profileData;
  const isSelf = props.isSelfProfile;
  console.log(profile?.posts);
  return (
    <div className={styles.main_layout}>
      <div className={styles.page_layout}>
        <div className={styles.main_feed_section}>
          {isSelf && <NewPostPrompt profile={profile} isProfilePrompt={true} />}
          <div className={styles.feed}>
            {profile?.posts?.map((post, index) => (
              <>
                <Post key={index} {...post} />
              </>
            ))}
            <div className={styles.feed_spacer} />
          </div>
        </div>
        <FeedPageSuggested profile={profile} title="You Also May Know" />
      </div>
      <FavoritedResources profile={profile} />
    </div>
  );
}

export default ProfilePageContent;
