import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { auth } from "../services/firebase";
import { db } from "../services/firebase";
import { storage } from "../services/firebase"; // Import storage separately
import { collection, query, orderBy, getDocs } from "firebase/firestore";

export const AdminPortalContext = createContext();

export const AdminPortalProvider = ({ children }) => {
  const [allUsers, setAllUsers] = useState([]);
  const [hubSpotContacts, setHubSpotContacts] = useState([]);

  const fetchAllUsers = async () => {
    try {
      const usersRef = collection(db, "Accounts");
      const querySnapshot = await getDocs(usersRef);

      const users = [];
      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          users.push({ id: doc.id, ...userData });
        }
      });

      setAllUsers(users);
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  };

  const fetchHubSpotContacts = async () => {
    const functionUrl =
      "https://us-central1-lorenzo-s-house.cloudfunctions.net/api/fetch-hubspot-contacts";

    try {
      const response = await axios.get(functionUrl);
      const contacts = response.data.contacts;
      setHubSpotContacts(contacts);
    } catch (error) {
      console.error("Error fetching HubSpot contacts:", error);
    }
  };

  useEffect(() => {
    fetchAllUsers();
    fetchHubSpotContacts();
  }, []);

  return (
    <AdminPortalContext.Provider
      value={{
        allUsers,
        hubSpotContacts,
      }}
    >
      {children}
    </AdminPortalContext.Provider>
  );
};
