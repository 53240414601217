import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import styles from "./feed.module.css";
import SearchBarHeader from "../universal_components/search_bar_header";
import { FeedContext } from "../../../context/FeedContext";
import axios from "axios";
import { AuthContext } from "../../../context/AuthContext";
import Post from "./feed_post";
import NewPostPrompt from "./feed_create_new_post";
import FeedPageSuggested from "./feed_page_suggested";
import FeedSidebar from "./feed_page_sidebar";

function Feed() {
  const { feed, matchedUsers, loadingFeed } = useContext(FeedContext);
  const { profile } = useContext(AuthContext);

  return (
    <div className={styles.main_layout}>
      <div className={styles.page_layout}>
        <div className={styles.main_feed_section}>
          <NewPostPrompt profile={profile} />
          {/* <div className={styles.page_divider}></div> */}
          <div className={styles.feed}>
            {feed.map((post, index) => (
              <Post key={index} {...post} />
            ))}
          </div>
        </div>
        <FeedPageSuggested title="People You May Know" />
      </div>
      <FeedSidebar />
    </div>
  );
}

export default Feed;
