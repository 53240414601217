import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./settings.module.css";
import AccountSettings from "./settings_tabs/accountSettings";
import VisibilitySettings from "./settings_tabs/visibilitySettings";
import NotificationSettings from "./settings_tabs/notificationSettings";
import { AuthContext } from "../../../context/AuthContext";

export default function SettingsComponent() {
  const { profile } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState("Account");
  // const tabs = ["Account", "Manage Visibility", "Notifications"];
  const tabs = ["Account", "Manage Visibility"];
  const tabsRef = useRef(null);
  const underlineRef = useRef(null);

  useEffect(() => {
    const tabEl = [...tabsRef.current.children].find(
      (child) => child.textContent === activeTab
    );
    const { offsetLeft, offsetWidth } = tabEl;
    underlineRef.current.style.left = `${offsetLeft}px`;
    underlineRef.current.style.width = `${offsetWidth}px`;
  }, [activeTab]);

  return (
    <div className={styles.accountContainer}>
      <p className={styles.settingsTitle}>Account</p>
      <div className={styles.tabs} ref={tabsRef}>
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`${styles.tab} ${activeTab === tab && styles.active}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </div>
        ))}
        <div className={styles.underline} ref={underlineRef} />
      </div>
      <div className={styles.tab_layout}>
        {activeTab === "Account" && <AccountSettings profile={profile} />}
        {activeTab === "Manage Visibility" && <VisibilitySettings />}
        {/* {activeTab === "Notifications" && <NotificationSettings />} */}
      </div>
    </div>
  );
}
