// import "./chat.css";
import { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import React from "react";
import styles from "./inbox.module.css";
import InboxSideProfile from "./inboxSideProfile";
import InboxMessageInput from "./inboxMessageInput";
import { ChatContext } from "../../../context/ChatContext";
import { AuthContext } from "../../../context/AuthContext";

export default function InboxComponent() {
  const [isEditing, setIsEditing] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const {
    conversations,
    selectedChat,
    setSelectedChat,
    updateLastOpened,
    fetchSingleConnection,
  } = useContext(ChatContext);
  const { profile, unreadMessages, setUnreadMessages } =
    useContext(AuthContext);
  const { id: connectionId } = useParams();

  const storedUser = JSON.parse(localStorage.getItem("authUser"));
  const USER_ID = storedUser.uid;

  const fetchAndSetSelectedChat = async () => {
    if (connectionId) {
      try {
        console.log("FETCHING CONNECTION");
        const connection = await fetchSingleConnection(connectionId, USER_ID);

        console.log(connection);

        if (connection) {
          selectChat(connection);
          // updateLastOpened(connectionId);
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    }
  };

  useEffect(() => {
    if (connectionId) {
      fetchAndSetSelectedChat();
    }
  }, [connectionId]);

  const textAreaRef = useRef(null);
  useEffect(() => {
    if (isEditing && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [isEditing]);

  const selectChat = (chat) => {
    setSelectedChat(chat);
    updateLastOpened(chat.id);
  };

  useEffect(() => {
    if (selectedChat) {
      updateLastOpened(selectedChat.id);
    }
  }, [selectedChat]);

  function formatTimestampTo12Hour(timestamp) {
    const date = new Date(timestamp);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutesStr = minutes < 10 ? "0" + minutes : minutes;

    return hours + ":" + minutesStr + " " + ampm;
  }
  const getUnreadMessagesCountForChat = (chat) => {
    const storedUser = JSON.parse(localStorage.getItem("authUser"));
    const currentUserId = storedUser?.uid;

    const lastOpened = chat?.lastOpened?.[currentUserId];
    if (!lastOpened) {
      console.error("Invalid lastOpened timestamp.");
      return 0;
    }

    const unreadMessages = chat?.messages?.filter((message) => {
      return (
        message?.sender !== currentUserId && message?.timestamp > lastOpened
      );
    });

    return unreadMessages.length;
  };

  // useEffect(() => {
  //   const calculateTotalUnreadMessagesCount = () => {
  //     let totalUnreadMessagesCount = 0;

  //     for (const chat of conversations) {
  //       totalUnreadMessagesCount += getUnreadMessagesCountForChat(chat);
  //     }
  //     return totalUnreadMessagesCount;
  //   };
  //   const totalUnreadMessagesCount = calculateTotalUnreadMessagesCount();
  //   setUnreadMessages(totalUnreadMessagesCount);
  // }, [conversations]);

  const sortMessagesByTimestamp = (messages) => {
    return messages.slice().sort((a, b) => a.timestamp - b.timestamp);
  };

  const timeFilteredChatChannels = (conversations || [])
    .map((chat) => ({
      ...chat,
      lastMessage:
        chat.messages.length > 0
          ? sortMessagesByTimestamp(chat.messages)[chat.messages.length - 1]
          : null,
    }))
    .sort((chatA, chatB) => {
      if (!chatA.lastMessage || !chatB.lastMessage) {
        return 0;
      }
      const lastMessageTimestampA = new Date(chatA.lastMessage.timestamp);
      const lastMessageTimestampB = new Date(chatB.lastMessage.timestamp);
      return lastMessageTimestampB - lastMessageTimestampA;
    });

  const connectionsWithMessages = timeFilteredChatChannels.filter(
    (conversation) => conversation.messages.length !== 0
  );

  const chatChannels =
    connectionsWithMessages &&
    connectionsWithMessages.map((chat) => (
      <React.Fragment key={chat.id}>
        <div className={styles.chat_card_box}>
          <div
            onClick={() => selectChat(chat)}
            className={`${styles.chat_card} ${
              selectedChat?.id === chat.id
                ? styles.selected_chat
                : styles.non_selected_chat
            }`}
          >
            <div className={`${styles.chat_info} `}>
              <div className={styles.chat_all_info}>
                <div className={styles.chat_user_info}>
                  <div className={styles.chat_user_info_left}>
                    <div className={styles.chat_user_avatar}>
                      <img
                        src={chat.account.profilePicture}
                        className={styles.chat_user_avatar_image}
                        alt={"Profile"}
                      />
                    </div>

                    <div className={styles.user_info_box}>
                      <p className={styles.chat_user_name}>
                        {chat.account.firstname} {chat.account.lastname}
                      </p>
                      <p className={styles.chat_user_name_sub}>
                        {chat.account.position}
                      </p>
                    </div>
                  </div>
                  <div className={styles.chat_user_info_right}>
                    <div className={styles.chat_time_stamp}>
                      {chat.messages[0] && (
                        <p className={styles.chat_time_stamp_text}>
                          {formatTimestampTo12Hour(
                            sortMessagesByTimestamp(chat.messages)[
                              chat.messages.length - 1
                            ].timestamp
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.chat_recent_message}>
                  <p className={styles.chat_message}>
                    {chat.messages[chat?.messages?.length - 1]?.content}
                  </p>
                  <div className={styles.message_spacer}></div>
                  <p className={styles.chat_conversatsion_details}>
                    Care Partner
                  </p>
                </div>
              </div>
              {getUnreadMessagesCountForChat(chat) !== 0 && (
                <div className={styles.unread_side}>
                  <div className={styles.unread_bubble}>
                    {chat.messages[0] && (
                      <p className={styles.unread_messages_text}>
                        {getUnreadMessagesCountForChat(chat)}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    ));

  const MessageComponent = ({ selectedChat }) => {
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
    };

    useEffect(scrollToBottom, [selectedChat]);

    return (
      <div className={styles.message_wrapper}>
        {selectedChat.messages &&
          sortMessagesByTimestamp(selectedChat.messages).map(
            (message, index) => {
              const isSenderSelf = message.sender === USER_ID;

              return (
                <div
                  key={index}
                  className={`${styles.message_container} ${
                    isSenderSelf ? styles.reversed : ""
                  }`}
                >
                  <div
                    className={`${styles.message_avatar} ${
                      isSenderSelf
                        ? styles.message_avatar_self
                        : styles.message_avatar_other
                    }`}
                  >
                    <img
                      className={styles.message_avatar_image}
                      src={
                        isSenderSelf
                          ? profile?.profilePicture
                          : selectedChat?.account?.profilePicture
                      }
                      alt={isSenderSelf ? "Self" : "Other"}
                    />
                  </div>
                  <div
                    className={`${styles.message_content} ${
                      isSenderSelf
                        ? styles.self_message_content
                        : styles.other_message_content
                    }`}
                  >
                    <div
                      className={`${styles.message_info} ${
                        isSenderSelf
                          ? styles.self_message_info
                          : styles.other_message_info
                      }`}
                    >
                      <p className={styles.message_sender}>
                        {isSenderSelf
                          ? `${profile?.firstname} ${profile?.lastname}`
                          : `${selectedChat.account.firstname} ${selectedChat.account.lastname}`}
                      </p>

                      <span className={styles.message_time}>
                        {formatTimestampTo12Hour(message.timestamp)}
                      </span>
                    </div>

                    <div
                      className={`${styles.message_text_content} ${
                        isSenderSelf
                          ? styles.self_message_text_content
                          : styles.other_message_text_content
                      }`}
                    >
                      <p className={styles.message_text}>{message.content}</p>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        <div ref={messagesEndRef} />
      </div>
    );
  };

  return (
    <div className={styles.main_layout}>
      <div className={styles.sidebar}>
        {/* SEARCH BUTTON */}
        <div className={styles.search_container}>
          <input
            className={styles.search_input}
            value={searchQuery}
            type="text"
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search"
          />
        </div>
        {/* <div className={styles.divider}></div> */}
        {timeFilteredChatChannels.length !== 0 ? (
          <div className={styles.chat_list}>{chatChannels}</div>
        ) : (
          <div className={styles.empty}>
            <img className={styles.empty_image} src="/emptyInbox.png"></img>
            <p className={styles.empty_text}>No Messages</p>
          </div>
        )}
      </div>

      {selectedChat ? (
        <>
          <div className={styles.chat_container}>
            <div className={styles.chat_layout}>
              <div className={styles.chat_layout_content_info}>
                {/* <div className={styles.chat_header}>
                <div className={styles.chat_avatar}></div>
                <div className={styles.chat_name}>{selectedChat.name}</div>
              </div> */}
                <div className={styles.chat_messages}>
                  <MessageComponent selectedChat={selectedChat} />
                </div>
              </div>
              <div className={styles.chat_input}>
                <InboxMessageInput selectedChat={selectedChat} />
              </div>
            </div>
          </div>
          <InboxSideProfile selectedChat={selectedChat} />
        </>
      ) : (
        <div className={styles.no_chat_selected}>
          <div className={styles.select_chat_box}>
            <p className={styles.select_chat_text}>Select a Chat</p>
            <img
              className={styles.select_chat_image}
              src="/images/general_icons/NoSelectedChatIcon.svg"
              alt="no chat"
            ></img>
          </div>
        </div>
      )}
      {/* {selectedChat ? (
        <InboxSideProfile selectedChat={selectedChat} />
      ) : (
        <div className={styles.no_chat_selected}>
          <div className={styles.select_chat_box}>
            <p className={styles.select_chat_text}>Select a Chat</p>
            <img className={styles.select_chat_image}></img>
          </div>
        </div>
      )} */}
    </div>
  );
}
