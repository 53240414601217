import React from "react";
import LinkedinAuthComponent from "../../components/auth_components/signup_page/linkedin_auth_component";

const LinkedinAuthPage = () => {
  return (
    <>
      <LinkedinAuthComponent />
    </>
  );
};

export default LinkedinAuthPage;
