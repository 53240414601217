import React from "react";
import { useContext } from "react";

import styles from "./feed.module.css";
import SearchBarHeader from "../universal_components/search_bar_header";
import { FeedContext } from "../../../context/FeedContext";
import axios from "axios";
import { AuthContext } from "../../../context/AuthContext";

function FeedSidebar() {
  const { feed } = useContext(FeedContext);

  return (
    <div className={styles.right_sidebar}>
      {/* <p className={styles.sidebar_title}>Upcoming Events</p> */}
      <SidebarPromotion />
      <ImpactReportPromotion />
    </div>
  );
}

function SidebarPromotion() {
  return (
    <div className={styles.promotion_tile}>
      <div className={styles.promotion_top_info}>
        <img
          src="/YouthSummitImage.png"
          className={styles.promotion_image}
        ></img>
      </div>
      <div className={styles.promotion_title_text}>
        <p className={styles.promotion_title}>
          LORENZO'S HOUSE WORLDWIDE VIRTUAL YOUTH SUMMIT 2024
        </p>
        <p className={styles.promotion_subtitle}>June 14th - June 15th</p>
      </div>
      <p className={styles.promotion_description}>
        A free virtual worldwide event that unites children, teens and young
        adults of a parent living with, or has lived with younger-onset dementia
        of any form. An inspiring day together to share common story, exchange
        carer strategies, move forward advocacy initiatives and build community.
      </p>
      <div className={styles.promotion_controls}>
        <div className={styles.learn_more_button}>Learn More</div>
        <div className={styles.register_button}>Register</div>
      </div>
    </div>
  );
}

function ImpactReportPromotion() {
  return (
    <div className={styles.promotion_tile}>
      {/* <div className={styles.promotion_top_info}>
        <img
          src="/ImpactReportCover.png"
          className={styles.promotion_image}
        ></img>
      </div> */}
      <div className={styles.no_image_title_text}>
        <p className={styles.promotion_title}>
          Check out our 2022 Impact Report
        </p>
      </div>
      <p className={styles.promotion_description}>
        A look at the impact and growth we saw over our 2022 year!
      </p>
      <div className={styles.promotion_controls}>
        <div className={styles.check_out_button}>Check Out</div>
      </div>
    </div>
  );
}

export default FeedSidebar;
