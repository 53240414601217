import styles from "./navbar.module.css";
import { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router";
import NotificationsSidebar from "../Notifications/Notifications";
import { AuthContext } from "../../context/AuthContext";

export default function Navbar(props) {
  const [isEditingGuidelines, setIsEditingGuidelines] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const firebaseLink =
    "https://console.firebase.google.com/u/0/project/lorenzo-s-house/overview";

  const googleCloudPlatformLink =
    "https://console.cloud.google.com/home/dashboard?project=lorenzo-s-house";

  const loggingLink =
    "https://console.cloud.google.com/logs/query;cursorTimestamp=2024-02-10T14:38:46.644831121Z?project=lorenzo-s-house";

  const handleLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const { logout, profile } = useContext(AuthContext);
  async function handleLogout() {
    try {
      await logout();
      navigate("/signin");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }

  const navigate = useNavigate();
  const navigateTo = (url) => {
    return navigate(url);
  };

  function switchToUserMode() {
    navigate("/dashboard");
  }

  function handleSettings() {
    navigate("/settings");
  }

  function handleProfile() {
    navigate("/profile");
  }

  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef();
  const chatbotMenuRef = useRef();
  const toggleAccountMenuRef = useRef();
  const toggleChatbotMenuRef = useRef();

  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const handleNotificationsClick = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (event) => {
    if (
      toggleAccountMenuRef.current &&
      toggleAccountMenuRef.current.contains(event.target)
    ) {
      if (showMenu) return;
    }

    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }

    if (
      toggleChatbotMenuRef.current &&
      toggleChatbotMenuRef.current.contains(event.target)
    ) {
      if (isOpen) return;
    }

    if (
      chatbotMenuRef.current &&
      !chatbotMenuRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.portal_navbar}>
      <div className={styles.portal_navbar_sidebar_extension}></div>

      <div className={styles.portal_navbar_content}>
        <div className={styles.portal_navbar_left_content}></div>
        <div className={styles.portal_navbar_right_content}>
          {notificationsOpen && (
            <div className={styles.notifications_sidebar}>
              <NotificationsSidebar />
            </div>
          )}
          <div className={styles.container_button} ref={menuRef}>
            {profile && (
              <div className={styles.account_navbar_item} onClick={toggleMenu}>
                <img
                  src={profile.profilePicture || "/DefaultAccountImage.png"}
                  alt="icon"
                  className={styles.account_pfp}
                ></img>
                <div className={styles.account_info}>
                  <p className={styles.account_name}>
                    {profile.firstname} {profile.lastname}
                  </p>
                  {profile.position && (
                    <p className={styles.account_position}>
                      {profile.position}
                    </p>
                  )}
                </div>

                {/* <img
                  src="/DropDownIconBlack.png"
                  alt="dropdown"
                  className={`${styles.dropdown_icon_icon} ${
                    showMenu ? styles.rotate_180 : ""
                  }`}
                ></img> */}
              </div>
            )}
            {showMenu && (
              <div className={styles.menu}>
                {/* <div
                  className={styles.menu_item}
                  onClick={() => handleLink(firebaseLink)}
                >
                  <img
                    className={styles.account_menu_icon}
                    src="/database.png"
                    alt="icon"
                  ></img>
                  <p className={styles.menu_item_text}>Firebase</p>
                </div> */}
                {/* <div
                  className={styles.menu_item}
                  onClick={() => handleLink(googleCloudPlatformLink)}
                >
                  <img
                    className={styles.account_menu_icon}
                    src="/server.png"
                    alt="icon"
                  ></img>
                  <p className={styles.menu_item_text}>Google Cloud</p>
                </div> */}
                {/* <div
                  className={styles.menu_item}
                  onClick={() => handleLink(loggingLink)}
                >
                  <img
                    className={styles.account_menu_icon}
                    src="/logging.png"
                    alt="icon"
                  ></img>
                  <p className={styles.menu_item_text}>Logging</p>
                </div> */}

                <div className={styles.menu_item} onClick={handleProfile}>
                  <img
                    className={styles.account_menu_icon}
                    src="/AccountMenuIcon.png"
                    alt="icon"
                  ></img>
                  <p className={styles.menu_item_text}>My Profile</p>
                </div>
                <div className={styles.menu_item} onClick={handleSettings}>
                  <img
                    className={styles.account_menu_icon}
                    src="/SettingsMenuIcon.png"
                    alt="icon"
                  ></img>
                  <p className={styles.menu_item_text}>Settings</p>
                </div>
                <div className={styles.menu_item} onClick={handleSettings}>
                  <img
                    className={styles.account_menu_icon}
                    src="/HelpMenuIcon.png"
                    alt="icon"
                  ></img>
                  <p className={styles.menu_item_text}>Help & Support</p>
                </div>
                <div className={styles.menu_divider_box}>
                  <div className={styles.menu_divider_small} />
                </div>
                <div className={styles.menu_item} onClick={handleLogout}>
                  <img
                    className={styles.account_menu_icon}
                    src="/LogoutMenuIcon.png"
                    alt="icon"
                  ></img>
                  <p className={styles.menu_item_text}>Logout</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
