import React from "react";
import SignUpComponent from "../../components/auth_components/signup_page/signup_component";
import LinkedinAuthComponent from "../../components/auth_components/signup_page/linkedin_auth_component";

const SignUpPage = () => {
  return (
    <>
      <LinkedinAuthComponent />
    </>
  );
};

export default SignUpPage;
