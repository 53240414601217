import React from "react";
import { useState, useContext } from "react";

import styles from "./signup.module.css";

import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router";

const SignIn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { signIn, createAccount, error } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    signIn(username, password)
      .then((userCredential) => {
        if (userCredential) {
          navigate("/community");
        }
      })
  
      .catch((error) => {
        console.error("Error during sign-in:", error);
        // Optionally, show the user an error message here
      });
  };

  const canContinue = username !== "" && password;

  return (
    <div className={styles.home_components}>
      <div className={styles.home_header_content}>
        <div className={styles.sidebar}>
          <img
            src="/LorenzosHouseLogos/LorenzosHouseHorizontalLogo.png"
            alt="logo"
            className={styles.logo}
          ></img>
          <p className={styles.join_waitlist}>Welcome Back</p>

          <div className={styles.email_input}>
            <input
              onChange={(event) => setUsername(event.target.value)}
              placeholder="Email"
              className={styles.input}
            ></input>
          </div>
          <div className={styles.email_input}>
            <input
              type="password"
              onChange={(event) => setPassword(event.target.value)}
              placeholder="Password"
              className={styles.input}
            ></input>
          </div>

          {canContinue ? (
            <div onClick={handleSubmit} className={styles.join_button}>
              <p className={styles.join_button_text}>Sign In</p>
            </div>
          ) : (
            <div className={styles.join_button_inactive}>
              <p className={styles.join_button_text}>Sign In</p>
            </div>
          )}
        </div>
      </div>
      <img
        className={styles.landing_image}
        src="/LGBGImages/BGImage2Flipped.jpeg"
        alt="landing"
      ></img>
    </div>
  );
};

export default SignIn;
