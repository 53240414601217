import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";
import styles from "./admin.module.css";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import AdminUsersPage from "../../components/admin_components/admin_users_page/adminUsersPage";
import HubspotContactsPage from "../../components/admin_components/AdminHubspotContactsPage/HubspotContactsPage";
import { AdminPortalProvider } from "../../context/AdminPortalContext";

const AdminPage = () => {
  const menuItems = [
    {
      label: "Manage Users",
      key: "manage-users",
      component: AdminUsersPage,
    },
    {
      label: "Manage Programs",
      key: "manage-programs",
      component: AdminUsersPage,
    },
    {
      label: "Manage Resources",
      key: "manage-resources",
      component: AdminUsersPage,
    },
    {
      label: "Hubspot Contacts",
      key: "hubspot-contacts",
      component: HubspotContactsPage,
    },
    // {
    //   label: "Pending Users",
    //   key: "pending-users",
    //   component: AdminUsersPage,
    // },
    // {
    //   label: "Admin Chat",
    //   key: "admin-chat",
    //   component: AdminChatbotTestingComponent,
    // },
    // {
    //   label: "Manage Inquiries",
    //   key: "manage-inquiries",
    //   component: AdminChatbotTestingComponent,
    // },
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const [activeComponentKey, setActiveComponentKey] = useState(
    menuItems[0].key
  );

  useEffect(() => {
    const activeItem = menuItems.find((item) =>
      location.pathname.includes(item.key)
    );
    if (activeItem) {
      setActiveComponentKey(activeItem.key);
    }
  }, [location]);

  const renderComponent = () => {
    const activeItem = menuItems.find(
      (item) => item.key === activeComponentKey
    );
    return activeItem ? <activeItem.component /> : null;
  };

  const handleMenuItemClick = (key) => {
    setActiveComponentKey(key);
    navigate(`/admin/${key}`); // navigate to the new path
  };

  return (
    <>
      <AdminPortalProvider>
        <Sidebar
          admin={true}
          childComponent={
            <div className={styles.page_layout}>
              <div className={styles.sidebar}>
                {menuItems.map((item) => (
                  <div
                    key={item.key}
                    onClick={() => handleMenuItemClick(item.key)}
                    className={
                      activeComponentKey === item.key
                        ? styles.menu_item_selected
                        : styles.menu_item
                    }
                  >
                    {item.label}
                  </div>
                ))}
              </div>
              <div style={{ flex: 1 }}>{renderComponent()}</div>
            </div>
          }
          activeText="Admin"
        />
      </AdminPortalProvider>
    </>
  );
};

export default AdminPage;
