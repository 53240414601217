import React from "react";
import styles from "./programs.module.css";
import SearchBarHeader from "../universal_components/search_bar_header";

function Programs() {
  const integrations = [
    {
      id: "1",
      name: "Light Club",
      type: "Club",
      summary:
        "A virtual hangout for kids,  8 to 30+ of a parent with YOD to feel understood, share common stories, exchange mindfulness practices and build an alliance.",
      logo_image: "/EventPhotos/lightClub.jpg",
    },
    {
      id: "2",
      name: "Bright Brunch",
      type: "Club",
      summary:
        "A virtual space for female carepartners/spouses to feel understood, share common stories, exchange resources and cure isolation.",
      logo_image: "/EventPhotos/brightBrunch.png",
      color: "rgba(245, 119, 34, 0.2)",
      connected: false,
    },
    {
      id: "3",
      name: "Light Lounge",
      type: "Club",
      summary:
        "A virtual space for male carepartners/spouses to feel understood, share common stories, exchange resources and cure isolation.",
      logo_image: "/EventPhotos/lightLounge.jpg",
      color: "rgba(245, 119, 34, 0.2)",
      connected: false,
    },
    {
      id: "4",
      name: "Music, Movement & Community",
      type: "Club",
      summary:
        "A virtual space for male carepartners/spouses to feel understood, share common stories, exchange resources and cure isolation.",
      logo_image: "/EventPhotos/musicMovementMemory.png",
      color: "rgba(245, 119, 34, 0.2)",
      connected: false,
    },
    // {
    //   id: "2",
    //   name: "Shine-On",
    //   type: "Bright Brunch",
    //   summary: "This is ...",
    //   logo_image: "/EventPhotos/lightClub.jpg",
    //   color: "rgba(245, 119, 34, 0.2)",
    //   connected: false,
    // },
    // {
    //   id: "2",
    //   name: "Light Lounge",
    //   type: "Meeting Scheduler",
    //   summary: "This is ...",
    //   logo_image: "/EventPhotos/lightClub.jpg",
    //   color: "rgba(245, 119, 34, 0.2)",
    //   connected: false,
    // },
    // {
    //   id: "2",
    //   name: "Shine-On",
    //   type: "Music, Movement & Community",
    //   summary: "This is ...",
    //   logo_image: "/EventPhotos/lightClub.jpg",
    //   color: "rgba(245, 119, 34, 0.2)",
    //   connected: false,
    // },
    // {
    //   id: "2",
    //   name: "Shine-On",
    //   type: "Bright Brunch",
    //   summary: "This is ...",
    //   logo_image: "/EventPhotos/lightClub.jpg",
    //   color: "rgba(245, 119, 34, 0.2)",
    //   connected: false,
    // },
    // {
    //   id: "2",
    //   name: "Light Lounge",
    //   type: "Meeting Scheduler",
    //   summary: "This is ...",
    //   logo_image: "/EventPhotos/lightClub.jpg",
    //   color: "rgba(245, 119, 34, 0.2)",
    //   connected: false,
    // },
    // {
    //   id: "2",
    //   name: "Shine-On",
    //   type: "Music, Movement & Community",
    //   summary: "This is ...",
    //   logo_image: "/EventPhotos/lightClub.jpg",
    //   color: "rgba(245, 119, 34, 0.2)",
    //   connected: false,
    // },
  ];

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Programs</p>
      <SearchBarHeader />

      <div className={styles.integration_tiles}>
        {integrations.map((integration, index) => {
          return (
            <div className={styles.tile}>
              <div
                className={styles.tile_header}
                style={{ backgroundColor: integration.color }}
              >
                <img
                  className={styles.logo_image}
                  src={integration.logo_image}
                  alt="logo"
                ></img>
              </div>
              <div className={styles.shadow_overlay}>
                <div className={styles.content}>
                  <p className={styles.title}>{integration.name}</p>
                  <p className={styles.type}>{integration.type}</p>
                  <p className={styles.summary}>{integration.summary}</p>
                  {/* <div className={styles.connect_button}>Connect</div> */}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Programs;
