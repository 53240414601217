import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { auth } from "../services/firebase";
import { db } from "../services/firebase";
import { storage } from "../services/firebase"; // Import storage separately
import {
  onSnapshot,
  orderBy,
  query,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  where,
  updateDoc,
  deleteDoc,
  addDoc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const FeedContext = createContext();

export const FeedProvider = ({ children }) => {
  const [feed, setFeed] = useState([]);
  const [loadingFeed, setLoadingFeed] = useState([]);

  const [matchedUsers, setMatchedUsers] = useState([]);

  const [lastViewed, setLastViewed] = useState(null);

  const handlePost = async (postCaption, image, profile) => {
    try {
      const storedUser = JSON.parse(localStorage.getItem("authUser"));
      const currentUserId = storedUser?.uid;
      let imageURL = null;
      if (image) {
        console.log("IMAGE: ", image);
        const base64Data = image.split(",")[1];
        const blob = base64ToBlob(base64Data);

        imageURL = await uploadImage(blob, "post_images");
      }
      console.log("IMAGEURL", imageURL);
      const fullname = profile.firstname + " " + profile.lastname;
      const postData = {
        caption: postCaption,
        image: imageURL,
        timestamp: Date.now(),
        profile_image: profile.profilePicture,
        posted_by: currentUserId,
        name: fullname,
      };

      const postsRef = collection(db, "Posts");

      await addDoc(postsRef, postData);

      fetchFeed();
      fetchPostsByUser(currentUserId);
    } catch (error) {
      console.error("Error posting:", error);
    }
  };

  const deletePost = async (id) => {
    try {
      const postRef = doc(db, "Posts", id);

      await deleteDoc(postRef);

      setFeed((prevFeed) => prevFeed.filter((post) => post.id !== id));

      console.log("Post deleted successfully.");
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const fetchFeed = async () => {
    try {
      const q = query(collection(db, "Posts"), orderBy("timestamp", "desc"));
      const querySnapshot = await getDocs(q);
      const posts = [];
      querySnapshot.forEach((doc) => {
        posts.push({ id: doc.id, ...doc.data() });
      });
      console.log(posts);
      setFeed(posts);
    } catch (error) {
      console.error("Error fetching feed:", error);
    }
  };

  const fetchMatchedUsers = async () => {
    try {
      const usersRef = collection(db, "Accounts");
      const querySnapshot = await getDocs(usersRef);

      const users = [];
      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          users.push({ id: doc.id, ...userData });
        }
      });

      setMatchedUsers(users);
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  };

  const uploadImage = async (image, folder) => {
    try {
      const timestamp = Date.now();
      const storageRef = ref(storage, `post_images/${timestamp}`);

      await uploadBytes(storageRef, image);

      const downloadURL = await getDownloadURL(storageRef);

      return downloadURL;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const base64ToBlob = (base64Data) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: "image/png" });
  };

  const fetchPostsByUser = async (userId) => {
    try {
      const q = query(
        collection(db, "Posts"),
        orderBy("timestamp", "desc"),
        where("posted_by", "==", userId)
      );
      const querySnapshot = await getDocs(q);
      const posts = [];
      querySnapshot.forEach((doc) => {
        posts.push({ id: doc.id, ...doc.data() });
      });
      return posts;
    } catch (error) {
      console.error("Error fetching posts by user:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchFeed();
    fetchMatchedUsers();
  }, []);

  return (
    <FeedContext.Provider
      value={{
        feed,
        matchedUsers,
        handlePost,
        deletePost,
        fetchPostsByUser,
      }}
    >
      {children}
    </FeedContext.Provider>
  );
};
