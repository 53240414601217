import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAm5Bj8z90rpdDMLe5YHrzWkypenhvUX2w",
  authDomain: "lorenzo-s-house.firebaseapp.com",
  projectId: "lorenzo-s-house",
  storageBucket: "lorenzo-s-house.appspot.com",
  messagingSenderId: "134482993618",
  appId: "1:134482993618:web:c93a79071bcc791f11680b",
  measurementId: "G-FZXSR5FYTV",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, auth, db, storage };
