import React from "react";
import { useState } from "react";
import styles from "./adminusers.module.css";

export default function HubspotContact(props) {
  const contact = props.contact;

  return (
    <div className={styles.user_profile}>
      <div className={styles.user_profile_layout}>
        <img
          src="/DefaultAccountImage.png"
          alt="profile"
          className={styles.user_profile_image}
        ></img>
        <div className={styles.user_profile_text}>
          <p className={styles.user_profile_name}>
            {contact.firstname} {contact.lastname}
          </p>
          <p className={styles.user_profile_genre}>{contact.email}</p>
        </div>
      </div>
      <div className={styles.column_title}>{contact.email}</div>
      <div className={styles.column_title_last}>
        {formatDateWithOrdinalSuffix(contact.lastmodifieddate)}
      </div>
    </div>
  );
}

function formatDateWithOrdinalSuffix(dateString) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(dateString);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  const suffix = getDaySuffix(day);

  return `${month} ${day}${suffix}, ${year}`;
}

function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}
