import { useState, useRef, useEffect } from "react";
import { useContext } from "react";
import React from "react";
import styles from "./creator_search.module.css";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../../../context/ChatContext";
import { toast } from "react-toastify";

function formatNumber(num) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  } else {
    return num;
  }
}

function formatTimestampToDate(timestamp) {
  try {
    const date = new Date(parseInt(timestamp, 10));

    const options = { year: "numeric", month: "long", day: "numeric" };

    return date.toLocaleDateString(undefined, options);
  } catch (error) {
    console.error("Error in formatting timestamp:", error);
    return "";
  }
}

export default function ConnectionCard(props) {
  const navigate = useNavigate();
  const { connection, isChecked, onCheck } = props;

  const {
    setConnections,
    connections,
    acceptConnectionRequest,
    cancelConnectionRequest,
  } = useContext(ChatContext);

  function encrypt(url) {
    return btoa(url);
  }

  function removeConnectionFromState(connectionId) {
    setConnections(
      connections.filter((connection) => connection.id !== connectionId)
    );
  }

  function goToChat(event) {
    event.stopPropagation();
    navigate(`/inbox/${connection.id}`);
  }

  function goToProfile() {
    navigate(`/profile/${connection.account.uid}`);
  }

  function acceptRequest(event) {
    event.stopPropagation();
    acceptConnectionRequest(connection.id);
    toast.success("Connection Request Accepted!");
    removeConnectionFromState(connection.id);
  }

  function cancelRequest(event) {
    event.stopPropagation();
    cancelConnectionRequest(connection.id, connection.account.uid);
    removeConnectionFromState(connection.id);
  }

  const tileClass = isChecked
    ? styles.creator_tile_layout_checked
    : styles.creator_tile_layout;

  return (
    <div className={tileClass} onClick={goToProfile}>
      <div className={styles.creator_tile_margin}>
        <div className={styles.checkbox_field}></div>
        <div className={styles.account_info}>
          <img
            className={styles.creator_profile_picture}
            src={
              connection.account.profilePicture || "/DefaultAccountImage.png"
            }
            alt="profile"
          ></img>
          <div className={styles.creator_display_names}>
            <p className={styles.creator_name}>
              {connection.account.firstname} {connection.account.lastname}
            </p>
            <p className={styles.creator_username}>{connection.position}</p>
          </div>
        </div>
        <div className={styles.tile_date_section}>
          {props.activeTab === "Connected" ? (
            <p className={styles.creator_stat}>
              {formatTimestampToDate(connection.dateConnected)}
            </p>
          ) : (
            <p className={styles.creator_stat}>
              {formatTimestampToDate(connection.dateInitiated)}
            </p>
          )}
        </div>
        <div className={styles.tile_section}>
          <p className={styles.creator_stat}>
            {connection.account.relationship}
          </p>
        </div>
        <div className={styles.tile_section}>
          <p className={styles.creator_stat}>{connection.account.age}</p>
        </div>
        <div className={styles.connect_button_section}>
          {props.activeTab === "Connected" && (
            <div className={styles.control_buttons}>
              <div className={styles.chat_button} onClick={goToChat}>
                Chat
              </div>
              <MoreButton cancelRequest={cancelRequest} />
            </div>
          )}
          {props.activeTab === "Requests" && (
            <div className={styles.control_buttons}>
              <div className={styles.ignore_button} onClick={cancelRequest}>
                Ignore
              </div>
              <div className={styles.accept_button} onClick={acceptRequest}>
                Accept
              </div>
            </div>
          )}
          {props.activeTab === "Pending" && (
            <div className={styles.rescind_button} onClick={cancelRequest}>
              Rescind
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function MoreButton(props) {
  const id = props.id;
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = (event) => {
    event.stopPropagation();
    setShowMenu(!showMenu);
  };

  return (
    <div className={styles.more_layout} ref={menuRef}>
      <div className={styles.more_button} onClick={toggleMenu}>
        <div className={styles.more_button_dot}> </div>
        <div className={styles.more_button_dot}> </div>
        <div className={styles.more_button_dot}> </div>
      </div>
      {showMenu && (
        <div className={styles.menu}>
          <div className={styles.menu_item}>
            <p className={styles.menu_item_text}>View Profile</p>
          </div>
          <div className={styles.menu_item} onClick={props.cancelRequest}>
            <p className={styles.menu_item_text}>Disconnect</p>
          </div>
          <div className={styles.menu_item}>
            <p className={styles.menu_item_text}>Report</p>
          </div>
        </div>
      )}
    </div>
  );
}
