import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import styles from "./resources.module.css";
import { useState, useContext } from "react";
import SearchBarHeader from "../universal_components/search_bar_header";
import { toast } from "react-toastify";
import { CreateTrainingDataPopup } from "../../screen_popups/CreateTrainingDataPopup/CreateTrainingDataPopup";
import destructureJsonData from "../../../utils/destructureJsonData";
import CircularLoader from "../universal_components/CircularLoader/CircularLoader";

import { useNavigate } from "react-router";

const Resources = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedTrainingData, setSelectedTrainingData] = useState(null);
  const [createPopup, setCreatePopup] = useState(false);

  const [loadingTrainingDataFile, setLoadingTrainingDataFile] = useState(false);
  const [loadingPopup, setLoadingPopup] = useState(false);

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");

  function handleModalNav(selectedTrainingData) {
    setSelectedTrainingData(selectedTrainingData);
    console.log(selectedTrainingData);
    setIsEditing(!isEditing);
  }

  function back() {
    setIsEditing(!isEditing);
    setSelectedTrainingData(null);
    navigate(`/admin/training-data`);
  }

  const handleCreateNew = async (event) => {
    event.stopPropagation();
    setCreatePopup(true);
  };

  return (
    <div className={styles.main_layout}>
      {loadingTrainingDataFile ? (
        <div className={styles.loading_page}>
          <CircularLoader size="50px" color="rgba(0, 61, 130, 1)" />
        </div>
      ) : (
        <>
          {!isEditing ? (
            <p className={styles.page_title_margin}>Resources</p>
          ) : (
            <div className={styles.header_section}></div>
          )}
          <div className={styles.edit_guidelinees_modal}>
            <>
              <div className={styles.guideline_controls}>
                <SearchBarHeader
                  type="EditChatbot"
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
                <div className={styles.guideline_add} onClick={handleCreateNew}>
                  <img
                    src="/add.png"
                    className={styles.add_guideline}
                    alt="add"
                  ></img>
                </div>
              </div>

              <div className={styles.training_data_container}>
                {resources.map((data, index) => {
                  const readyToTrain = data.verified;
                  return (
                    <div className={styles.data_set} key={index}>
                      <div className={styles.data_set_top}>
                        <div className={styles.training_data_text}>
                          <p className={styles.training_data_title}>
                            {data.name}
                          </p>
                          <p className={styles.training_data_subtitle}>
                            {data.subtitle}
                          </p>
                        </div>
                        {readyToTrain ? (
                          <div className={styles.top_right}>
                            <p className={styles.ready_text}>
                              Verefied Resource
                            </p>
                            <CheckMarkIcon />
                          </div>
                        ) : (
                          <div className={styles.top_right}>
                            {/* <p className={styles.not_ready_text}>
                              Validation Failed
                            </p>
                            <CrossIcon /> */}
                          </div>
                        )}
                      </div>
                      <div className={styles.data_set_bottom}>
                        <p className={styles.training_data_last_updated}>
                          Last Updated At: {formatTimestamp(data.last_updated)}
                        </p>
                        <div
                          className={styles.open_data_button}
                          onClick={() => handleModalNav(data)}
                        >
                          View Resource
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className={styles.spacer}></div>
              </div>
            </>
          </div>
          <CreateTrainingDataPopup
            openModal={createPopup}
            closeModal={() => setCreatePopup(false)}
            loading={loadingPopup}
            // actionFunction={createTrainingData}
            desc={"Create New Training Data"}
          />
        </>
      )}
    </div>
  );
};

function formatTimestamp(timestamp) {
  try {
    const date = new Date(parseInt(timestamp, 10));

    const options = {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    return date.toLocaleString(undefined, options);
  } catch (error) {
    console.error("Error in formatting timestamp:", error);
    return "";
  }
}

function CheckMarkIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 6L9 17L4 12"
        // stroke="currentColor"
        className={styles.check_svg}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function CrossIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18M6 6l12 12"
        className={styles.red_cross_svg}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Resources;

const resources = [
  {
    name: "Article on the benefits of diet for dementia patients",
    subtitle:
      "Discover the impact of nutrition on cognitive health and explore dietary recommendations for individuals with dementia.",
    verified: true,
    last_updated: new Date(),
  },
  {
    name: "Guide to managing dementia symptoms at home",
    subtitle:
      "Explore comprehensive strategies and practical tips for managing common symptoms of dementia within the comfort of home.",
    verified: false,
    last_updated: new Date(),
  },
  {
    name: "Webinar: Understanding Alzheimer's disease progression and treatment options",
    subtitle:
      "Join renowned experts as they delve into the stages of Alzheimer's disease, discuss treatment options, and provide insights for caregivers.",
    verified: true,
    last_updated: new Date(),
  },
  {
    name: "Effective communication techniques for caregivers of dementia patients",
    subtitle:
      "Learn proven communication strategies and techniques to enhance interactions with individuals suffering from dementia, promoting better understanding and connection.",
    verified: true,
    last_updated: new Date(),
  },
  {
    name: "Research study: Effects of music therapy on dementia patients' mood and behavior",
    subtitle:
      "Delve into the results of a groundbreaking research study examining the therapeutic effects of music on the mood and behavior of individuals with dementia, shedding light on its potential benefits for care practices.",
    verified: false,
    last_updated: new Date(),
  },
  {
    name: "The role of physical activity in managing cognitive decline",
    subtitle:
      "Gain insights into the importance of physical exercise in mitigating cognitive decline and improving overall brain health, along with practical tips for incorporating exercise into daily routines.",
    verified: true,
    last_updated: new Date(),
  },
  {
    name: "Interactive workshop: Enhancing quality of life for dementia patients through sensory stimulation",
    subtitle:
      "Participate in an interactive workshop designed to explore the sensory experiences of individuals with dementia and learn innovative techniques for enhancing their quality of life through sensory stimulation.",
    verified: false,
    last_updated: new Date(),
  },
  // Add more resources as needed
];
