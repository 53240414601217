import React from "react";
import { useContext, useState } from "react";
import styles from "./universal.module.css";
// import FilterMenu from "../universal_components/filter_menu";
import { AuthContext } from "../../../context/AuthContext";

function SearchBarHeader(props) {
  const { prospects, conversations, activeDealStages, inactiveDealStages } =
    useContext(AuthContext);
  const filterType = props.type;
  // State to determine whether the menu is open or not
  const [menuOpen, setMenuOpen] = useState(false);
  const dealStages = activeDealStages + inactiveDealStages;
  function handleFilterClick() {
    // Toggle the menu state when filter button is clicked
    setMenuOpen((prevState) => !prevState);
    console.log(conversations);
  }
  return (
    <div className={styles.search_layout}>
      <div className={styles.search_bar}>
        <img
          className={styles.search_icon}
          src="/images/GeneralIcons/SearchIcon.png"
          alt="search"
        ></img>
        <div className={styles.search_divider}></div>
        <input
          placeholder="Search"
          className={styles.search_input}
          value={props.searchTerm}
          onChange={(e) => props.setSearchTerm(e.target.value)}
        ></input>
      </div>
      {false && (
        <div className={styles.search_filter} onClick={handleFilterClick}>
          <img
            className={styles.search_filter_icon}
            src="/images/GeneralIcons/FilterIcon.png"
            alt="filter"
          ></img>
        </div>
      )}
      {props.create && (
        <div className={styles.add_button} onClick={handleFilterClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="20"
            height="20"
            fill="rgba(44, 78, 133, 0.95)"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M11 2h2v20h-2z" />
            <path d="M2 11h20v2H2z" />
          </svg>
        </div>
      )}
    </div>
  );
}

export default SearchBarHeader;
