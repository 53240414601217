import React from "react";
import { useState } from "react";
import { useContext } from "react";
// import { useHistory } from "react-router-dom";

import { useNavigate } from "react-router-dom";
// import firebase from "../../../services/firebase";
// import { auth } from "../../../services/firebase";
// import { signInWithEmailAndPassword } from "firebase/auth";
import { AuthContext } from "../../../context/AuthContext";

import styles from "./signup.module.css";

const AuthInput = ({ title, onChange, value }) => {
  return (
    <div className={styles.user_info_layout}>
      <p className={styles.user_info_title}>{title}</p>
      <input className={styles.input_box} onChange={onChange} value={value} />
    </div>
  );
};

const LinkedinAuthComponent = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { callCloudRunFunction, error } = useContext(AuthContext);

  const navigate = useNavigate();

  const linkedinEmail = "justin@replyassist.com";
  const linkedinPassword = "Cashmoney$99";
  const useProxy = true;
  const proxyHost = "isp2.hydraproxy.com:9989";
  const proxyUsername = "just86207hlvb217388";
  const proxyPassword = "MkJ31dUPjvrnsCuE_country-UnitedStates";

  const handleSubmit = (event) => {
    event.preventDefault();
    callCloudRunFunction(
      linkedinEmail,
      linkedinPassword,
      useProxy,
      proxyHost,
      proxyUsername,
      proxyPassword
    ).then((response) => {
      console.log(response);
    });
  };

  return (
    <div className={styles.home_components}>
      <div className={styles.home_header_content}>
        <div className={styles.back_button} onClick={() => navigate("/home")}>
          <img
            className={styles.back_icon}
            src="/images/GeneralIcons/GeneralBackButton.png"
            alt="back"
          ></img>
          <p className={styles.back_text}>Back to Main</p>
        </div>

        <div className={styles.landing_header}>
          <div className={styles.auth_content_box}>
            <p>Sign Up</p>
            <p>Created by financial prospectors for financial prospectors</p>
            <AuthInput
              title="Linkedin Email"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
            />
            <AuthInput
              title="Linkedin Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <p>Terms and Service</p>
            <button onClick={handleSubmit}>
              <p>Create my account</p>
            </button>
          </div>
        </div>
      </div>
      <img
        className={styles.landing_image}
        src="/images/AuthPages/authBackgroundImage.jpg"
        alt="landing"
      ></img>
    </div>
  );
};

export default LinkedinAuthComponent;
