import React from "react";
import { useContext, useState, useEffect } from "react";
import styles from "./adminusers.module.css";
import SearchBarHeader from "../../portal_components/universal_components/search_bar_header";
import HubspotContact from "./HubspotContact";
import { AdminPortalContext } from "../../../context/AdminPortalContext";

function HubspotContactsPage() {
  const { allUsers, hubSpotContacts } = useContext(AdminPortalContext);
  const [filteredUsers, setFilteredUsers] = useState(allUsers);

  const [searchTerm, setSearchTerm] = useState("");

  //   useEffect(() => {
  //     const filtered = hubSpotContacts.filter((contact) =>
  //       contact.properties.firstname
  //         .toLowerCase()
  //         .includes(searchTerm.toLowerCase())
  //     );
  //     setFilteredUsers(filtered);
  //   }, [searchTerm, hubSpotContacts]);

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Lorenzo's House HubSpot Contacts</p>
      <SearchBarHeader
        type="AdminUsers"
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <div className={styles.column_titles}>
        <p className={styles.column_title_info}>Basic Info</p>
        <p className={styles.column_title}>Email</p>
        <p className={styles.column_title_last}>Last Updated</p>
      </div>
      {hubSpotContacts.length !== 0 && (
        <div className={styles.prospect_scroll}>
          {hubSpotContacts.map((contact, index) => {
            return <HubspotContact key={index} contact={contact.properties} />;
          })}
          <div className={styles.buffer}></div>
        </div>
      )}
    </div>
  );
}

export default HubspotContactsPage;
