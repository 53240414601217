import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import Feed from "../../components/portal_components/FeedPage/FeedPage";

const CommunityPage = () => {
  return (
    <>
      <Sidebar childComponent={<Feed />} activeText="Community" />
    </>
  );
};

export default CommunityPage;
