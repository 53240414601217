import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { auth } from "../services/firebase";
import { db } from "../services/firebase"; // Import db separately
import { storage } from "../services/firebase"; // Import storage separately
import { AuthContext } from "./AuthContext";
import {
  query,
  orderBy,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  where,
  writeBatch,
  onSnapshot,
  limit,
  addDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [loading, setIsLoading] = useState(null);

  const [selectedChat, setSelectedChat] = useState(null);
  const [connections, setConnections] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [unsubscribeFunctions, setUnsubscribeFunctions] = useState({});
  const [currentFilters, setCurrentFilters] = useState();
  const [loadingConnections, setLoadingConnections] = useState(true);
  const { profile } = useContext(AuthContext);

  //   useEffect(() => {
  //     const storedUser = localStorage.getItem("authUser");
  //     if (storedUser) {
  //       const user = JSON.parse(storedUser);
  //       localStorage.setItem("USER_ID", user.uid);
  //       setCurrentUser(user);
  //       fetchConversations(user.uid);
  //     }

  //     const unsubscribe = auth.onAuthStateChanged(async (user) => {
  //       if (user) {
  //         setCurrentUser(user);
  //         localStorage.setItem("authUser", JSON.stringify(user));
  //         fetchUserData(user.uid);
  //         const connections = await fetchConnections(user.uid);
  //         setConnections(connections);
  //         const conversations = await fetchConversations(user.uid);
  //         setConversations(conversations);
  //       } else {
  //         setCurrentUser(null);
  //         setProfile(null);
  //         localStorage.removeItem("authUser");
  //       }
  //     });

  //     return () => unsubscribe(); // Clean up the subscription
  //   }, []);

  //   const sendConnectionRequest = async (accountData) => {
  //     try {
  //       const accountsRef = collection(db, "accounts");
  //       const connectionsRef = collection(db, "connections");

  //       let accountID;

  //       const matchingAccounts = await getMatchingAccounts(
  //         accountsRef,
  //         formattedAccountData
  //       );
  //       if (matchingAccounts.length > 0) {
  //         accountID = matchingAccounts[0].id;

  //         const existingAccountRef = doc(accountsRef, accountID);
  //         await updateDoc(existingAccountRef, formattedAccountData);

  //         console.log("Matching account found and updated.");
  //       } else {
  //         const newAccountRef = doc(accountsRef);
  //         await setDoc(newAccountRef, {
  //           ...formattedAccountData,
  //           id: newAccountRef.id,
  //         });
  //         accountID = newAccountRef.id;
  //       }

  //       const newConnectionRef = doc(connectionsRef);
  //       const connectionData = {
  //         accounts: [localStorage.getItem("USER_ID"), accountID],
  //         id: newConnectionRef.id,
  //         platform: formattedAccountData.platform,
  //         status: "PENDING",
  //         dateInitiated: new Date(),
  //       };

  //       await setDoc(newConnectionRef, connectionData);

  //       return newConnectionRef.id;
  //     } catch (error) {
  //       console.error("Error in sendConnectionRequest:", error);
  //       return false;
  //     }
  //   };

  const updateMessagesForConnection = (connectionId, newMessages) => {
    setConversations((prevConnections) => {
      return prevConnections.map((connection) => {
        if (connection.id === connectionId) {
          return { ...connection, messages: newMessages };
        }
        return connection;
      });
    });
  };

  const fetchConnections = async (currentTab) => {
    console.log(currentTab);
    const storedUser = JSON.parse(localStorage.getItem("authUser"));
    const userId = storedUser?.uid;
    if (!userId) {
      return;
    }
    setLoadingConnections(true);
    try {
      const connectionsRef = collection(db, "Connections");
      let q;
      if (currentTab === "Connected") {
        q = query(
          connectionsRef,
          where("status", "==", "ACCEPTED"),
          where("accounts", "array-contains", userId),
          orderBy("dateInitiated", "desc")
        );
      } else if (currentTab === "Requests") {
        q = query(
          connectionsRef,
          where("status", "==", "PENDING"),
          where("accounts", "array-contains", userId),
          orderBy("dateInitiated", "desc")
        );
      } else if (currentTab === "Pending") {
        q = query(
          connectionsRef,
          where("status", "==", "PENDING"),
          where("accounts", "array-contains", userId),
          orderBy("dateInitiated", "desc")
        );
      } else {
        q = query(
          connectionsRef,
          where("status", "==", "ACCEPTED"),
          where("accounts", "array-contains", userId),
          orderBy("dateInitiated", "desc")
        );
      }
      const querySnapshot = await getDocs(q);

      const connections = [];

      for (const docSnapshot of querySnapshot.docs) {
        const data = docSnapshot.data();
        const accountId = data.accounts.find((uid) => uid !== userId);
        let userAccount = null;

        if (accountId) {
          const otherUserRef = doc(db, "Accounts", accountId);
          const otherUserSnap = await getDoc(otherUserRef);
          userAccount = otherUserSnap.exists() ? otherUserSnap.data() : null;
        }

        const isFirstUser = data.accounts[0] === userId;
        const isSecondUser = data.accounts[1] === userId;

        if (
          currentTab === "Connected" ||
          (currentTab === "Requests" && !isFirstUser) ||
          (currentTab === "Pending" && isFirstUser)
        ) {
          connections.push({
            id: docSnapshot.id,
            ...data,
            account: userAccount,
          });
        }
      }

      console.log(
        "User connections and other user's data fetched successfully."
      );
      if (currentTab === "Requests") {
      }
      if (currentTab === "Pending") {
      }
      setLoadingConnections(false);
      return connections;
    } catch (error) {
      setLoadingConnections(false);
      console.error(
        "Error fetching user connections and other user's data:",
        error
      );
      throw error;
    }
  };

  const fetchConversations = async () => {
    const storedUser = JSON.parse(localStorage.getItem("authUser"));
    const userId = storedUser?.uid;
    if (!userId) {
      return;
    }
    try {
      const connectionsRef = collection(db, "Connections");
      const q = query(
        connectionsRef,
        where("accounts", "array-contains", userId),
        orderBy("latestMessageTimestamp", "desc")
      );

      const querySnapshot = await getDocs(q);

      const connections = [];
      const setupListenersPromises = [];

      for (const docSnapshot of querySnapshot.docs) {
        const data = docSnapshot.data();
        const accountId = data.accounts.find((uid) => uid !== userId);
        let userAccount = null;

        const messages = [];

        if (accountId) {
          const otherUserRef = doc(db, "Accounts", accountId);
          const otherUserSnap = await getDoc(otherUserRef);
          userAccount = otherUserSnap.exists() ? otherUserSnap.data() : null;
        }

        const messagesRef = collection(
          db,
          `Connections/${docSnapshot.id}/messages`
        );

        const queryMessages = query(
          messagesRef,
          orderBy("timestamp", "desc"),
          limit(100)
        );

        const unsubscribe = onSnapshot(queryMessages, (snapshot) => {
          const newMessages = snapshot.docChanges().map((change) => {
            if (change.type === "added") {
              return { id: change.doc.id, ...change.doc.data() };
            }
            return null;
          });

          const filteredNewMessages = newMessages.filter((msg) => msg !== null);
          messages.push(...filteredNewMessages);
          updateMessagesForConnection(docSnapshot.id, messages);
        });

        setupListenersPromises.push(unsubscribe);

        connections.push({
          id: docSnapshot.id,
          ...data,
          account: userAccount,
          messages,
          unsubscribe,
        });
      }

      await Promise.all(setupListenersPromises);

      console.log(
        "User connections, other user's data, and messages fetched successfully."
      );

      setConversations(connections);
    } catch (error) {
      console.error(
        "Error fetching user connections, other user's data, and messages:",
        error
      );
      throw error;
    }
  };

  const fetchSingleConnection = async (connectionId, userId) => {
    try {
      const connectionRef = doc(db, "connections", connectionId);
      const connectionDoc = await getDoc(connectionRef);

      if (!connectionDoc.exists()) {
        return null;
      }

      const data = connectionDoc.data();
      const accountId = data.accounts.find((uid) => uid !== userId);
      let userAccount = null;

      if (accountId) {
        const otherUserRef = doc(db, "accounts", accountId);
        const otherUserSnap = await getDoc(otherUserRef);
        userAccount = otherUserSnap.exists() ? otherUserSnap.data() : null;
      }

      const messagesRef = collection(connectionRef, "messages");
      const q = query(messagesRef, orderBy("timestamp", "desc"), limit(100));

      const messages = [];

      const unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            messages.push({ id: change.doc.id, ...change.doc.data() });
          }
        });
      });

      return {
        id: connectionId,
        ...data,
        account: userAccount,
        messages,
        unsubscribe,
      };
    } catch (error) {
      console.error("Error setting up messages listener:", error);
      throw error;
    }
  };

  useEffect(() => {
    return () => {
      Object.values(unsubscribeFunctions).forEach((unsubscribe) => {
        if (typeof unsubscribe === "function") {
          unsubscribe();
        }
      });
    };
  }, [unsubscribeFunctions]);

  const sendMessage = async (connectionId, messageContent) => {
    const newMessageRef = doc(
      collection(db, `Connections/${connectionId}/messages`)
    );

    const currentTimestamp = Date.now();

    await setDoc(newMessageRef, {
      content: messageContent,
      timestamp: currentTimestamp,
      sender: profile.uid,
    });

    const connectionDocRef = doc(db, "Connections", connectionId);

    await updateDoc(connectionDocRef, {
      latestMessageTimestamp: currentTimestamp,
    });
  };

  const updateLastOpened = async (chatId) => {
    const chatRef = doc(db, "Connections", chatId);

    const storedUser = JSON.parse(localStorage.getItem("authUser"));
    const userId = storedUser?.uid;
    if (!userId) {
      return;
    }

    const lastOpenedField = `lastOpened.${userId}`;
    const currentTime = Date.now();

    try {
      await updateDoc(chatRef, {
        [lastOpenedField]: currentTime,
      });

      // Update the local state as well
      setConversations((prevConnections) => {
        return prevConnections.map((chat) => {
          if (chat.id === chatId) {
            return {
              ...chat,
              lastOpened: {
                ...chat.lastOpened,
                [userId]: currentTime,
              },
            };
          }
          return chat;
        });
      });
    } catch (error) {
      console.error("Error updating last opened timestamp:", error);
    }
  };

  useEffect(() => {
    // Your existing code for updating selectedChat based on connections
    if (selectedChat) {
      const updatedChat = conversations.find((c) => c.id === selectedChat.id);
      if (updatedChat) {
        setSelectedChat(updatedChat);
      } else {
        // The previously selected chat no longer exists
        setSelectedChat(null); // or set to a default chat
      }
    }
  }, [conversations, selectedChat]); // Include selectedChat as a dependency

  const postFeedback = async (accountID, message) => {
    try {
      const feedbackRef = collection(db, "feedback");

      const docRef = await addDoc(feedbackRef, {
        accountID: accountID,
        message: message,
        timestamp: Date.now(),
      });
      console.log("Feedback added with ID: ", docRef.id);
    } catch (error) {
      console.error("Error adding feedback: ", error);
    }
  };

  const sendConnectionRequest = async (otherUserId) => {
    try {
      const storedUser = JSON.parse(localStorage.getItem("authUser"));
      const currentUserId = storedUser?.uid;

      if (!currentUserId || !otherUserId) {
        throw new Error("Current user or other user ID is missing.");
      }

      const connectionsRef = collection(db, "Connections");

      // Query to check if current user is part of any existing connections involving otherUserId
      const currentUserConnectionsQuery = query(
        connectionsRef,
        where("accounts", "array-contains", currentUserId)
      );
      const currentUserConnectionsSnapshot = await getDocs(
        currentUserConnectionsQuery
      );

      // Query to check if other user is part of any existing connections involving currentUserId
      const otherUserConnectionsQuery = query(
        connectionsRef,
        where("accounts", "array-contains", otherUserId)
      );
      const otherUserConnectionsSnapshot = await getDocs(
        otherUserConnectionsQuery
      );

      let existingConnectionId = null;

      // Check if there's any overlap in the results
      currentUserConnectionsSnapshot.forEach((currentUserConnection) => {
        const currentUserConnectionId = currentUserConnection.id;
        otherUserConnectionsSnapshot.forEach((otherUserConnection) => {
          if (currentUserConnectionId === otherUserConnection.id) {
            existingConnectionId = currentUserConnectionId;
          }
        });
      });

      if (existingConnectionId) {
        // If a connection already exists, update its status to "PENDING"
        const existingConnectionRef = doc(connectionsRef, existingConnectionId);
        await updateDoc(existingConnectionRef, {
          status: "PENDING",
        });

        await updateDoc(existingConnectionRef, {
          accounts: [currentUserId, otherUserId],
        });

        console.log("Connection status updated to PENDING.");
        return existingConnectionId;
      } else {
        // If no existing connection, create a new one
        const newConnectionDoc = await addDoc(connectionsRef, {
          dateConnected: null,
          dateInitiated: Date.now(),
          accounts: [currentUserId, otherUserId],
          latestMessageTimestamp: null,
          status: "PENDING",
        });

        const userRef = doc(db, "Accounts", currentUserId);
        await updateDoc(userRef, {
          connected_users: arrayUnion(otherUserId),
        });

        const otherUserRef = doc(db, "Accounts", otherUserId);
        await updateDoc(otherUserRef, {
          connected_users: arrayUnion(currentUserId),
        });

        console.log("Connection request sent successfully.");
        return newConnectionDoc.id;
      }
    } catch (error) {
      console.error("Error sending connection request:", error);
      throw error;
    }
  };

  const acceptConnectionRequest = async (connectionId) => {
    try {
      const connectionRef = doc(db, "Connections", connectionId);

      await updateDoc(connectionRef, {
        status: "ACCEPTED",
        dateConnected: Date.now(),
      });

      await sendMessage(connectionId, "Accepted Your Connection Request");

      console.log("Connection request accepted successfully.");
    } catch (error) {
      console.error("Error accepting connection request:", error);
      throw error;
    }
  };

  const cancelConnectionRequest = async (connectionId, otherUserId) => {
    try {
      const storedUser = JSON.parse(localStorage.getItem("authUser"));
      const currentUserId = storedUser?.uid;

      if (!currentUserId || !otherUserId) {
        throw new Error("Current user or other user ID is missing.");
      }

      const connectionRef = doc(db, "Connections", connectionId);

      await updateDoc(connectionRef, {
        status: "DISCONNECTED",
      });

      const currentUserRef = doc(db, "Accounts", currentUserId);
      await updateDoc(currentUserRef, {
        connected_users: arrayRemove(otherUserId),
      });

      const otherUserRef = doc(db, "Accounts", otherUserId);
      await updateDoc(otherUserRef, {
        connected_users: arrayRemove(currentUserId),
      });

      console.log("Connection request canceled successfully.");
    } catch (error) {
      console.error("Error canceling connection request:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchConversations();
  }, []);

  return (
    <ChatContext.Provider
      value={{
        error,
        loading,
        connections,
        conversations,
        sendMessage,
        selectedChat,
        setSelectedChat,
        updateLastOpened,
        currentFilters,
        sendConnectionRequest,
        fetchSingleConnection,
        fetchConnections,
        setConnections,
        postFeedback,
        loadingConnections,
        setLoadingConnections,
        acceptConnectionRequest,
        cancelConnectionRequest,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

// async function countDocumentsInCollection() {
//     const coll = collection(db, "locations"); // Replace "locations" with your collection name
//     const snapshot = await getCountFromServer(coll);
//     console.log("Total count:", snapshot.data().count);
// //   }
