import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import styles from "./feed.module.css";
import { FeedContext } from "../../../context/FeedContext";
import { useNavigate } from "react-router-dom";

// const testURL = "https://lorenzoshouse.org/2022-impact-report/";

export default function Post(props) {
  console.log("Image", props.image);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setShowOverlay(true);
  };

  const Overlay = () => {
    return (
      <div className={styles.overlay} onClick={() => setShowOverlay(false)}>
        <img
          className={styles.overlayImage}
          src={selectedImage}
          alt="Overlay Image"
        />
      </div>
    );
  };

  const navigate = useNavigate();

  function goToProfile() {
    navigate(`/profile/${props.posted_by}`);
  }

  return (
    <div className={styles.user_post}>
      <div>
        <div className={styles.post_top}>
          <div className={styles.post_top_left}>
            <img
              onClick={goToProfile}
              className={styles.profile_image}
              src={props.profile_image}
              alt="Profile"
            ></img>
            <div className={styles.post_top_text}>
              <p className={styles.post_name} onClick={goToProfile}>
                {props.name}
              </p>
              <p className={styles.post_subtitle} onClick={goToProfile}>
                Care Partner
              </p>
            </div>
          </div>
          <div className={styles.post_top_right}>
            <MoreButton id={props.id} />
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.post_middle}>
          <p className={styles.post_caption}>{props.caption}</p>
          {props.image && (
            <img
              className={styles.post_image}
              src={props.image}
              onClick={() => handleImageClick(props.image)}
              alt="Post Image"
            ></img>
          )}
        </div>
        <div className={styles.divider} />
        <div className={styles.post_bottom}>
          <div className={styles.post_actions}>
            <p className={styles.action_text}>Like</p>
            <p className={styles.action_text}>Comment</p>
          </div>
          <p className={styles.post_timestamp}>
            {formatTimestampToDate(props.timestamp)}
          </p>
        </div>
      </div>
      {showOverlay && <Overlay />}
    </div>
  );
}

function MoreButton(props) {
  const id = props.id;
  const { deletePost } = useContext(FeedContext);
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleDelete = () => {
    deletePost(id);
    setShowMenu(false); // Close the menu after deleting the post
  };

  return (
    <div className={styles.more_layout} ref={menuRef}>
      <div className={styles.more_button} onClick={toggleMenu}>
        <div className={styles.more_dot}> </div>
        <div className={styles.more_dot}> </div>
        <div className={styles.more_dot}> </div>
      </div>
      {showMenu && (
        <div className={styles.menu}>
          <div className={styles.menu_item}>
            <p className={styles.menu_item_text}>Like</p>
          </div>
          <div className={styles.menu_item}>
            <p className={styles.menu_item_text}>Report</p>
          </div>
          <div className={styles.menu_item} onClick={handleDelete}>
            <p className={styles.menu_item_text}>Delete</p>
          </div>
        </div>
      )}
    </div>
  );
}

function formatTimestampToDate(timestamp) {
  try {
    const date = new Date(parseInt(timestamp, 10));

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return date.toLocaleDateString(undefined, options);
  } catch (error) {
    console.error("Error in formatting timestamp:", error);
    return "";
  }
}

// const [previewData, setPreviewData] = useState(null);

// const findUrl = (text) => {
//   const urlRegex = /(https?:\/\/[^\s]+)/g;
//   return text.match(urlRegex);
// };

// const fetchPreviewData = async (url) => {
//   try {
//     const response = await axios.post(
//       "https://api.linkpreview.net",
//       {
//         q: testURL,
//         key: "3d23496666342d8ce21606d49aea061e",
//       },
//       {
//         headers: {
//           "X-Linkpreview-Api-Key": "3d23496666342d8ce21606d49aea061e",
//         },
//       }
//     );
//     console.log(response.data);
//     setPreviewData(response.data);
//   } catch (error) {
//     console.error("Error fetching link preview:", error);
//   }
// };

// useEffect(() => {
//   const urls = findUrl(props.caption);
//   if (urls) {
//     fetchPreviewData();
//   }
// }, [props.caption]);
