import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./connections.module.css";
import ConnectionLayout from "./ConnectionLayout";
import { ChatContext } from "../../../context/ChatContext";

export default function ConnectionComponent() {
  const [activeTab, setActiveTab] = useState("Connected");
  const tabs = ["Connected", "Requests", "Pending"];
  const sortByDropdownOptions = ["Date Connected", "Relationship", "Age"];
  const { fetchConnections, setConnections, currentUser } =
    useContext(ChatContext);
  const tabsRef = useRef(null);
  const underlineRef = useRef(null);

  async function handleFetchConnections() {
    const connections = await fetchConnections(activeTab);
    console.log(connections);
    setConnections(connections);
  }

  useEffect(() => {
    const tabEl = [...tabsRef.current.children].find(
      (child) => child.textContent === activeTab
    );
    const { offsetLeft, offsetWidth } = tabEl;
    underlineRef.current.style.left = `${offsetLeft}px`;
    underlineRef.current.style.width = `${offsetWidth}px`;
    handleFetchConnections();
  }, [activeTab]);

  return (
    <div className={styles.main_layout}>
      <div className={styles.creator_search_component}>
        <p className={styles.page_title}>My Connections</p>
        <div className={styles.search_layout}>
          <input
            className={styles.searchbar}
            placeholder="Search your connections"
          ></input>
        </div>
      </div>
      <div className={styles.tabs} ref={tabsRef}>
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`${styles.tab} ${activeTab === tab && styles.active}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </div>
        ))}
        <div className={styles.underline} ref={underlineRef} />
      </div>
      <div className={styles.tab_layout}>
        <ConnectionLayout activeTab={activeTab} />
      </div>
    </div>
  );
}
