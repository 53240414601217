const main_menu = [
  {
    id: 1,
    title: "Admin",
    icon_image: "/images/SidebarIcons/SidebarAdminIcon.svg",
    iconKey: "stroke",
    url: "/admin",
    isSelf: true,
  },
  {
    id: 2,
    title: "Community",
    icon_image: "/images/SidebarIcons/SidebarDashboardIcon.svg",
    iconKey: "stroke",
    url: "/community",
    isSelf: true,
  },
  {
    id: 3,
    title: "Inbox",
    icon_image: "/images/SidebarIcons/SidebarChatIcon.svg",
    iconKey: "stroke",
    url: "/inbox",
    isSelf: true,
  },
  {
    id: 4,
    title: "My Connections",
    icon_image: "/images/SidebarIcons/SidebarContactsIcon.svg",
    iconKey: "stroke",
    url: "/connections",
    isSelf: true,
  },
  {
    id: 5,
    title: "Programs",
    icon_image: "/images/SidebarIcons/SidebarIntegrationsIcon.svg",
    iconKey: "stroke",
    url: "/programs",
    isSelf: true,
  },
  {
    id: 6,
    title: "Resources",
    icon_image: "/images/SidebarIcons/SidebarSupportIcon.svg",
    iconKey: "stroke",
    url: "/resources",
    isSelf: true,
  },
  // {
  //   id: 7,
  //   title: "Account",
  //   icon_image: "/images/SidebarIcons/SidebarAccountIcon.svg",
  //   iconKey: "stroke",
  //   url: "/settings",
  //   isSelf: true,
  // },
];

export default main_menu;

// {
//   id: 2,
//   title: "Campaigns",
//   icon_image: "/images/SidebarIcons/CampaignsIcon.svg",
//   iconKey: "stroke",
//   url: "/campaigns",
//   isSelf: true,
// },
// {
//   id: 7,
//   title: "Integrations",
//   icon_image: "/images/SidebarIcons/SidebarIntegrationsIcon.svg",
//   iconKey: "stroke",
//   url: "/integrations",
//   isSelf: true,
// },
// {
//   id: 7,
//   title: "Chatbot Testing",
//   icon_image: "/images/SidebarIcons/ChatbotTestingIcon.svg",
//   iconKey: "stroke",
//   url: "/chatbottesting",
//   isSelf: true,
// },
// {
//   id: 8,
//   title: "Feedback",
//   icon_image: "/images/SidebarIcons/SidebarSupportIcon.svg",
//   iconKey: "stroke",
//   url: "/feedback",
//   isSelf: true,
// },
