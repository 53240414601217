import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import Programs from "../../components/portal_components/ProgramsPage/programs";

const ProgramsPage = () => {
  return (
    <>
      <Sidebar childComponent={<Programs />} activeText="Integrations" />
    </>
  );
};

export default ProgramsPage;
